/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .my-comeos .mycomeos-contact-section {
  margin-top: 30px;
  margin-bottom: 0px;
  text-align: center;
  color: white;
}
.altai-theme-content #bodyView .my-comeos .mycomeos-contact-section span.h6 {
  color: white !important;
}
.altai-theme-content #bodyView .my-comeos .mycomeos-contact-section .mail {
  margin-top: 20px;
  display: inline-block;
  background-color: white;
  color: black;
  padding: 6px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  padding-bottom: 2px;
}
.altai-theme-content #bodyView .my-comeos .profile-picture.profile-picture-circle {
  width: 140px;
  height: 140px;
  overflow: hidden;
  border-radius: 100%;
  display: inline-block;
}
.altai-theme-content #bodyView .my-comeos .profile-picture.profile-picture-circle img {
  width: auto;
  height: auto;
  min-width: 140px;
  min-height: 140px;
}
.altai-theme-content #bodyView .profile {
  /* Tables
        ================================== */
  /* Table column sizing
        ================================== */
  /* Responsive
        ==================================== */
}
.altai-theme-content #bodyView .profile .editmode .checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #00B4E1;
  border-color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .checkbox-info input[type="checkbox"]:hover + label::before {
  background-color: #00B4E1;
  border-color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag.active .check {
  background-color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag.parent.active:hover .check {
  background-color: #d9d9d9;
  color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag.parent:hover .chevron {
  color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag.parent .check:hover {
  color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag:hover .check {
  background-color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag.active:hover .check {
  background-color: #d9d9d9;
  color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag-select-all.active .check {
  background-color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag-select-all:hover .check {
  background-color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .hasChildren {
  background-color: #00B4E1 !important;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag-container .subtag.active {
  border-left-color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag-container .subtag:hover {
  border-left-color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag.parent .check:hover {
  background-color: #00B4E1;
  color: #00B4E1;
}
.altai-theme-content #bodyView .profile .editmode .tag-container .title {
  text-decoration: none;
}
.altai-theme-content #bodyView .profile .editmode .tag:focus,
.altai-theme-content #bodyView .profile .editmode .tag-title-container:focus {
  outline: 0;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag-container .subtag:focus {
  outline: 0;
}
.altai-theme-content #bodyView .profile .editmode .tag-selector .tag .check:focus {
  outline: 0;
}
.altai-theme-content #bodyView .profile .subtitle {
  padding-top: 10px;
  padding-bottom: 20px;
}
.altai-theme-content #bodyView .profile .subtitle i {
  padding-left: 5px;
}
.altai-theme-content #bodyView .profile .buttons {
  padding-top: 20px;
  padding-bottom: 20px;
}
.altai-theme-content #bodyView .profile .buttons .altai-pill-button.blue {
  margin: 5px;
  margin-right: 20px;
  display: inline-block;
}
.altai-theme-content #bodyView .profile .buttons .altai-pill-button.blue :focus {
  outline: none;
}
.altai-theme-content #bodyView .profile .profile-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
}
.altai-theme-content #bodyView .profile .profile-info .altaiform select {
  min-width: 250px;
  padding: 6px 12px;
  height: 34px;
  font-size: 14px;
  border-radius: 4px;
}
.altai-theme-content #bodyView .profile .profile-info .attributes .attribute-item {
  padding: 10px 0;
  display: flex;
}
.altai-theme-content #bodyView .profile .profile-info .attributes .attribute-item .info-attribute {
  display: flex;
  width: 200px;
  font-weight: 600;
  /* vertical-align: top; */
  align-content: center;
  align-items: center;
}
.altai-theme-content #bodyView .profile .profile-info .attributes .attribute-item .info-value {
  display: flex;
}
.altai-theme-content #bodyView .profile .profile-info .attributes .attribute-item .info-value .vertical-align {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.altai-theme-content #bodyView .profile .profile-info .attributes .attribute-item .info-value .vertical-align md-checkbox {
  margin-bottom: inherit;
}
.altai-theme-content #bodyView .profile .profile-info .attributes .attribute-item .info-value .vertical-align md-checkbox.md-checked .md-icon {
  background-color: #00B4E1;
}
.altai-theme-content #bodyView .profile .profile-info .attributes .attribute-item .info-value .info-value-nonedit {
  /*display: flex;
                                align-content: flex-start;
                                align-items: center;
                                width: 300px;
                                overflow: hidden;
                                padding: 20px;
                                height: 1em;
                                font-size: 16px;
                                border-radius: 0;
                                line-height: 1em;
                                color: black;
                                border: none;
                                flex: 1;
                                -webkit-box-shadow: none;
                                box-shadow: none;*/
  height: 34px;
  padding: 6px 12px;
  border: 2px solid black;
  outline: none;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  width: 50%;
  min-width: 250px;
  background-color: #EDEDED;
}
.altai-theme-content #bodyView .profile .profile-info .attributes .attribute-item .info-value .altai-content-attribute-update-tpl {
  height: 1em;
  margin: 0;
  padding: 0;
}
.altai-theme-content #bodyView .profile .profile-info .attributes .attribute-item .info-value .form-control {
  /*width: 300px;
                                display: inline-block;
                                box-sizing: border-box;
                                margin: 0;
                                padding: 20px;
                                height: 1em;
                                font-size: 16px;
                                border-radius: 0;
                                line-height: 1em;
                                color: black;
                                border: none;
                                flex: 1;
                                -webkit-box-shadow: none;
                                box-shadow: none;*/
}
.altai-theme-content #bodyView .profile .profile-info .attributes .attribute-item .info-value .altai-content-attribute-update-lang {
  display: none;
}
.altai-theme-content #bodyView .profile .profile-info .profile-picture {
  display: flex;
  flex-direction: column;
}
.altai-theme-content #bodyView .profile .profile-info .profile-picture div {
  margin: 10px;
}
.altai-theme-content #bodyView .profile .profile-info .values {
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
}
.altai-theme-content #bodyView .profile .profile-info .values .altai-content-attribute-update-item-wrapper {
  display: block;
}
.altai-theme-content #bodyView .profile .subsubtitle {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 1.2em;
}
.altai-theme-content #bodyView .profile .settingstilte {
  font-weight: 600;
}
.altai-theme-content #bodyView .profile .altai-content-relation-update .header {
  display: block;
}
.altai-theme-content #bodyView .profile .altai-content-relation-update .header label {
  color: black;
  text-transform: none;
  margin-left: 0;
  font-size: 1.3em;
  margin-bottom: 15px;
}
.altai-theme-content #bodyView .profile .altai-content-relation-update .header label span {
  display: none;
}
.altai-theme-content #bodyView .profile .Rtable {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
.altai-theme-content #bodyView .profile .Rtable-cell {
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  list-style: none;
  padding-bottom: 5px;
}
.altai-theme-content #bodyView .profile .Rtable-cell > h1,
.altai-theme-content #bodyView .profile .Rtable-cell > h2,
.altai-theme-content #bodyView .profile .Rtable-cell > h3,
.altai-theme-content #bodyView .profile .Rtable-cell > h4,
.altai-theme-content #bodyView .profile .Rtable-cell > h5,
.altai-theme-content #bodyView .profile .Rtable-cell > h6 {
  margin: 0;
}
.altai-theme-content #bodyView .profile .Rtable-cell .value span {
  font-weight: 600;
}
.altai-theme-content #bodyView .profile .Rtable--2cols > .Rtable-cell {
  flex-basis: 50%;
}
.altai-theme-content #bodyView .profile .Rtable--3cols > .Rtable-cell {
  flex-basis: 33.33%;
}
.altai-theme-content #bodyView .profile .Rtable--4cols > .Rtable-cell {
  flex-basis: 25%;
}
.altai-theme-content #bodyView .profile .Rtable--5cols > .Rtable-cell {
  flex-basis: 20%;
}
.altai-theme-content #bodyView .profile .Rtable--6cols > .Rtable-cell {
  flex-basis: 16.6%;
}
@media all and (max-width: 500px) {
  .altai-theme-content #bodyView .profile .Rtable--collapse {
    display: block;
  }
  .altai-theme-content #bodyView .profile .Rtable--collapse > .Rtable-cell {
    flex-basis: 100% !important;
  }
  .altai-theme-content #bodyView .profile .Rtable--collapse > .Rtable-cell--foot {
    margin-bottom: 1em;
  }
}
.altai-theme-content #bodyView .profile .no-flexbox .Rtable {
  display: block;
}
.altai-theme-content #bodyView .profile .no-flexbox .Rtable > .Rtable-cell {
  flex-basis: 100%;
}
.altai-theme-content #bodyView .profile .no-flexbox .Rtable > .Rtable-cell--foot {
  margin-bottom: 1em;
}
.altai-theme-content #bodyView .altai-pill-button {
  font-weight: 700;
  margin-top: 8px;
  margin: auto;
  border-radius: 7px;
  display: inline-block;
  background-color: #e8e8e8;
  padding: 8px;
  font-size: 0.625em !important;
  letter-spacing: 1px;
}
.altai-theme-content #bodyView .altai-pill-button.blue {
  background-color: #00B4E1;
  color: white;
}
